<template>
    <div >
      <h1 class="fs-18 fw-700 heading">Weekly Assessments - Marks Entry</h1>
      <div class = "row">
        <div class="col l4 m4 s12 m-0 left-padding ">
          <select class="school-select pointer" id="select-input" @change="onBatchChange($event)" v-model="batchId">
            <option value="0" selected disabled>Select Batch</option>
            <option v-for="batch in batchList" :key="batch.Id" :value="batch.Id">
                {{ batch.Name }}
            </option>
          </select>
        </div>
        <div class="col l4 m4 s12 m-0 left-padding ">
          <select class="school-select pointer" id="select-input" v-model="instituteTestId" @change="onTestChange($event)">
            <option value="0" selected disabled>Select Test</option>
            <option v-for="test in testList" :key="test.InstituteTestId" :value="test.InstituteTestId">
                {{ test.Title }}
            </option>
          </select>
        </div>
        <div class="col l1 m1 s12 m-0 " >
            <button v-if="instituteTestId!= 0 " class="test-btn" @click="onLoad()" >Load</button>
        </div>
        <div class="col l1 m1 s12 m-0 ">
            <button v-if="studentList.length>0 "  class="test-btn" @click="onSave()" >Save</button>
        </div>
        </div>
        <span v-if="studentList.length > 0" style="opacity: 0.7;">Date of Test : {{startDateTime}}</span>
        <div class="table-container" v-if="studentList.length > 0">
        <div class="table-main" >
            <div class="table-header" :style="`grid-template-columns: 160px 80px 80px`">
                <p class="fs-14 fw-500">Student</p>
                <p class="fs-14 fw-500 ">Attendance</p>
                <p class="fs-14 fw-500 ">Total Marks</p>
            </div>
            <div class="student-detail white" :style="`grid-template-columns: 160px 80px 80px`" v-for="(item, index1) in studentList" :key="index1">
                <span >{{ item.Name }}   </span>
                <span><label class="switch">
                    <input type="checkbox" @click="toggleCheckbox(index1)" :checked="this.checkbox[index1]">
                    <div class="slider round"></div>
                </label></span>
                <span class="center-item total-count-display"  >{{ totalMarks[index1] }}</span>
            </div>
        </div>
        <div v-dragscroll class="table-main scroll-horizontal w-100" >
            <div class="table-header" :style="`grid-template-columns: repeat(${uniqueSectionNames.length}, 162px)`">
                <div v-for="(section, index) in uniqueSectionNames" :key="index">
                    <p class="fs-14 fw-500 center-item">{{ section.SectionName }}</p>
                </div>
            </div>
            <div class="student-detail white" :style="`grid-template-columns: repeat(${uniqueSectionNames.length}, 162px)`" v-for="(item, index1) in studentList" :key="index1">
                <input class=" center-item input-box fs-12 fw-600" v-for="(section, index2) in uniqueSectionNames" :key="index2" type="text" :name="`section_${index2}`" v-model="inputValues[index1][index2]" :disabled="!checkbox[index1]">
            </div>
        </div>
        </div>
      </div>
      </template>
<script>
import moment from "moment";
import { useToast } from "vue-toastification";
import Mobileapi from "../Mobileapi";

const toast = useToast();

export default {
  data() {
    return {
      batchList: [],
      batchId: 0,
      testList: [],
      instituteTestId: 0,
      studentList: [],
      uniqueSectionNames: [],
      totalMarks: [],
      inputValues: [],
      checkbox: [],
      startDateTime: null,
      affiliationId: null,
    };
  },
  watch: {
    inputValues: {
      handler() {
        this.calculateTotalMarks();
      },
      deep: true,
    },
  },
  beforeMount() {
    document.body.style.backgroundColor = "#F6F8FB";
  },
  created() {
    this.affiliationId = this.$store.getters.user.AffiliationId;
    Mobileapi.getAffiliationBatchList(this.affiliationId, { isActive: 1 }, (response) => {
      this.batchList = response.data;
    });
  },
  methods: {
    // index1 represents a particular student
    toggleCheckbox(index1) {
      this.checkbox[index1] = !this.checkbox[index1];
      if (!this.checkbox[index1]) {
        this.inputValues[index1] = [];
      }
    },
    calculateTotalMarks() {
      this.totalMarks = this.studentList.map((student, index) => {
        const marks = Object.values(this.inputValues[index]).reduce((total, value) => total + Number(value || 0), 0);
        return marks;
      });
    },
    onBatchChange() {
      this.$store.dispatch("showLoader", true);
      this.clearData();
      this.studentList = [];
      this.instituteTestId = 0;
      const data = { batchId: this.batchId };
      Mobileapi.getInstituteTestsForBatch(data, (response) => {
        this.testList = response.data;
        this.$store.dispatch("showLoader", false);
        this.testList = this.testList.sort((a, b) => new Date(b.StartDateTime) - new Date(a.StartDateTime));
      });
    },
    clearData() {
      this.inputValues = Array(this.studentList.length).fill().map(() => ([]));
      this.checkbox = Array(this.studentList.length).fill(true);
    },
    onTestChange() {
      this.clearData();
      this.studentList = [];
      const date = this.testList.filter((test) => test.InstituteTestId === this.instituteTestId).map((test) => test.StartDateTime);
      this.startDateTime = moment(date, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format("DD-MM-YYYY");
    },
    onSave() {
      this.$store.dispatch("showLoader", true);
      const sendData = {
        marksDetails: [],
        instituteTestId: this.instituteTestId,
      };
      for (let student = 0; student < this.studentList.length; student += 1) {
        if (this.checkbox[student]) {
          const userId = this.studentList[student].UserId;
          const marks = this.inputValues[student];
          for (let section = 0; section < this.uniqueSectionNames.length; section += 1) {
            const instituteTestPartId = this.uniqueSectionNames[section].InstituteTestPartId;
            const mark = marks[section];
            sendData.marksDetails.push({
              userId,
              instituteTestPartId,
              marks: mark,
            });
          }
        }
      }
      Mobileapi.addInstituteTestManualMarks(sendData, (response) => {
        if (response.responseCode === 200) {
          toast.success("Marks saved successfully!", {
            timeout: 2500,
          });
        } else {
          toast.info("Something went wrong", {
            timeout: 2500,
          });
        }
        this.$store.dispatch("showLoader", false);
      });
    },
    onLoad() {
      this.$store.dispatch("showLoader", true);
      Mobileapi.getStudentForAssignment({
        AffiliationId: this.affiliationId,
        BatchIds: this.batchId,
      }, (response) => {
        this.studentList = response.data;
        this.clearData();
        const data = { instituteTestId: this.instituteTestId };
        Mobileapi.getSectionsForTest(data, (res) => {
          this.uniqueSectionNames = res.data.map((section) => ({
            SectionName: section.SectionName,
            InstituteTestPartId: section.InstituteTestPartId,
          }));
          const testId = { instituteTestId: this.instituteTestId };
          Mobileapi.getInstituteTestManualMarks(testId, (resp) => {
            if (resp.data.length !== 0) {
              this.inputValues = this.studentList.map((student) => {
                const userId = student.UserId;
                const subarray = resp.data
                  .filter((mark) => Number(mark.UserId) === Number(userId))
                  .map((mark) => mark.Marks);
                return subarray.length > 0 ? subarray : [];
              });
            }
          });
          this.$store.dispatch("showLoader", false);
        });
      });
    },
  },
};
</script>
<style scoped>
.school-select {
    height: 28px;
    padding: 0;
    padding-left: 12px;
    color: #333333 !important;
    font-size: 12px;
    font-weight: 600;
    background: #FFFFFF;
    border: 1px solid #E0E4F0 !important;
    box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
    border-radius: 5px;
    margin-right: 10px;
  }
  select {
    border: 1px solid #808080 !important;
    background-color: white !important;
    height: 43px;
    border-radius: 4px;
    font-size: 14px;
    color: #808080;
    display: block;
  }
  select:focus {
    outline: none;
  }
  #select-input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url("../assets/Vector4.svg") no-repeat right;
    background-size: 38px 6px;
    height: 43px;
    padding-left: 7px;
    line-height: 20px;
  }
  select option:checked {
    color: #3751FF;
    font-weight: 600;
  }
  option:disabled {
    color: rgb(170, 170, 170) !important;
    font-weight: 500 !important;
  }
  select {
    overflow: hidden;
  }
  select::-webkit-scrollbar {
    width: 0;
  }
  select option {
    color: #333333 !important;
  }
  .table-container {
    display: flex;
    padding-bottom: 24px;
  }
  .table-main{
    display: grid;
    grid-gap: 12px;
    flex: 1;
}
.table-header, .student-detail{
    padding: 12px 24px;
    background: #F3F3F3;
border-radius: 4px 4px 0px 0px;
display: grid;
    grid-gap: 46px;
    height: 66px;
    align-items: center;
}
.switch {
    position: relative;
    display: inline-block;
    width: 41px;
    height: 20px;
    margin: 0 10px;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F3F3F3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: 0px;
    background-color: #FF0000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #F3F3F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #F3F3F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(22px);
    background-color: #007F00;}

.slider.round {
    border-radius: 34px;
}

.increaseHeight {
    height: 105px;
}

.slider.round:before {
    border-radius: 50%;
}
.input-box {
    width: 51px !important;
    height: 29px !important;
    background: #FFFFFF;
    border: 1px solid #E0E4F0 !important;
    border-radius: 4px !important;
    text-align: center;
    display: block;
  margin: 0 auto !important;
  text-align: center;
  }
  .total-count-display {
    height: 29px;
    width: 51px;
    background: #D7E6D2;
    border: 1px solid #16A085;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 28px;
    text-align: center;
  }
  .center-item{
    display: flex;
    justify-content: center;
  }
  .test-btn {
    font-weight: 600;
    font-size: 14px;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 9px 13px;
    cursor: pointer;
    background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%)
  }
  .heading{
    margin:17px 0
   }
  input:focus {
    outline: none;
    border: none;
  }
  @media screen and (max-width: 600px) {
   .school-select{
    margin-top: 10px;
   }
   .test-btn{
    margin-top: 10px;
   }
}
@media screen and (max-width: 992px) {
.heading{
    padding: 0 0.75rem;
}
 }
@media screen and (min-width: 992px) {
   .left-padding{
    padding-left: 0 !important;
   }
 }
</style>
